<template>
  <div class="video">
    <div class="title">
      <span>援助热线 </span>
    </div>
    <el-card class="condition_style2">
      <div class="flex">
        <div class="add">
          <el-button type="primary" size="small" @click="add('')">新增热线</el-button>
        </div>
        <el-form :inline="true" class="flex" :model="searchForm">
          <el-form-item>
            <el-input v-model="searchForm.provinceName" placeholder="请输入省名称" suffix-icon="el-icon-search" clearable>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="searchForm.hotLine" placeholder="请输入热线电话" suffix-icon="el-icon-search" clearable>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getList(true)">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData" style="width: 100%" class="table" :height="tableHeight">
        <el-table-column type="index" label="序号" width="60" align="center" fixed>
          <template slot-scope="scope">{{scope.$index+(current-1) * size + 1}}</template>
        </el-table-column>
        <el-table-column prop="areasName" label="区域" min-width="250" align="center">
          <template slot-scope="scope">
            {{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.districtName}}
          </template>
        </el-table-column>
        <el-table-column prop="workTime" label="工作时间" min-width="180" align="center"></el-table-column>
        <el-table-column prop="hotLine" label="热线" min-width="200" align="center"></el-table-column>
        <el-table-column prop="updateTime" label="最后操作时间" min-width="200" align="center"></el-table-column>
        <el-table-column prop="updateBy" label="最后操作人" min-width="100" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" width="130" align="center">
          <template slot-scope="scope">
            <div class="flex_just_center">
              <el-button type="text" size="small" @click="add(scope.row.id)">编辑
              </el-button>
              <el-button type="text" size="small" :disabled="scope.row.map?true:false"
                @click="changeState(scope.row,false)">删除 </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination :page="current" :size="size" :total="total" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"></pagination>
    </el-card>
    <!-- 新增科普音频 -->
    <modal :obj="{
        title: edit ? '编辑' : '新增',
        dialog: addDialog,
        dialogText: 'addDialog',
        form: addForm,
        ref: 'addForm',
        okText: '确认',
        cancelText: '取消',
        width:'750px'
      }" @submit="addSubmit(true)">
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-form-item label="区域：" prop="arr">
          <el-cascader class="w_100" v-model="addForm.arr" :options="areaLevelList"
            :props="{ value: 'id', label: 'name',checkStrictly: true}" @change="handleChange"></el-cascader>
        </el-form-item>
        <el-form-item label="热线电话：" prop="hotLine1">
          <el-input v-model="addForm.hotLine" placeholder="请输入热线电话" maxlength="18"></el-input>
        </el-form-item>
        <el-form-item label="服务时间：" prop="workTime">
          <el-input v-model="addForm.workTime" placeholder="周一至周日，8:00-17:00" maxlength="100"></el-input>
        </el-form-item>
      </el-form>
    </modal>
  </div>
</template>

<script>
  import pagination from "../../../components/pagination";
  import modal from "../../../components/modal";
  import {
    hotLineSave,
    hotLineUpdate,
    hotLineInfo,
    areaLevel,
    childAreas,
    getHotLineList,
    bannerState,
    deleteHotLine,
  } from "../../../api/api";
  export default {
    components: {
      pagination,
      modal,
    },
    data() {
      return {
        tableHeight: this.$util.getHeight(310),
        addDialog: false,
        size: 10, //每页条数
        current: 1, //当前条数
        total: 0,
        searchForm: {
          hotLine: "",
          provinceName: ""
        },
        areaLevelList: [],
        addForm: {
          arr: [],
          province: "",
          city: "",
          district: "",
          hotLine: "",
          workTime: "",
        },
        tableData: [],
        list: [{
          value: "",
          label: "全部",
        }, ],
        showBtn: false,
        rules: {
          arr: [{
            required: true,
            message: "请选择区域"
          }],
          hotLine: [{
            required: true,
            message: "请输入热线"
          }],

        },
        edit: "",
        typeList: [],
        addTypeList: [],
        startList: [{
          value: null,
          label: "全部",
        }, {
          value: "0",
          label: "上架",
        }, {
          value: "1",
          label: "下架",
        }, {
          value: "2",
          label: "待上架",
        }],
      };
    },
    computed: {},
    watch: {},
    created() {
      this.getAreaFourLevel();
      this.getList();
    },
    mounted() {
      window.addEventListener('resize', () => {
        let num = this.showBtn ? 260 : 300
        this.getTableHeight(num)
      })
    },
    methods: {
      getTableHeight(val) {
        this.tableHeight = this.$util.getHeight(val)
      },
      // 获取地区
      getAreaFourLevel() {
        this.$request(areaLevel).then((res) => {
          if (res.code === 0) {
            let list = res.data
            this.areaLevelList = list
          }
        });
      },
      // 选择地区
      handleChange() {
        this.addForm.district = ''
        this.addForm.city = ''
        this.addForm.province = ''
        let arr = this.addForm.arr;
        this.addForm.province = arr[0];
        this.addForm.city = arr[1] ? arr[1] : '';
        if (arr[2]) {
          this.addForm.district = arr[2];
        } else {
          this.addForm.district = '';
        }
        this.$forceUpdate()
      },
      getList(val) {
        if (val) {
          this.current = 1;
        }
        this.getTableHeight(330)
        this.$request(getHotLineList(this.current, this.size), this.searchForm).then((res) => {
          if (res.code === 0) {
            let data = res.data;
            this.tableData = data.records;
            this.total = Number(data.total);
          }
        });
      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.current = val;
        this.getList();
      },
      // 推荐、上架、删除
      changeState(val, bool) {
        let text;
        if (!bool) {
          text = "确定删除？";
        } else if (bool == 1) {
          text = val.status == 0 ? "确定下架？" : "确定上架？";
        }
        val.bool = bool;
        this.$util.isSwal(text, this.changeStateSubmit, val);
      },
      changeStateSubmit(val) {
        this.$request(deleteHotLine, {
          id: val.id
        }).then((res) => {
          if (res.code === 0) {
            this.$util.message("suc", "操作成功");
            this.getList();
          }
        });
      },
      //   编辑排序
      editSort() {
        let ids = [];
        let order = [];
        this.tableData.find((item) => {
          ids.push(item.id);
          order.push(item.orderNum);
        });
        this.$request(batchOrderNews, {
          ids,
          order
        }).then((res) => {
          if (res.code === "200") {
            this.showBtn = false;
            this.$util.message("suc", "操作成功");
            this.getList();
          }
        });
      },
      //   新增科普音频-弹框
      add(val) {
        this.loading = false
        this.addForm = {
          arr: [],
          province: "",
          city: "",
          district: "",
          hotLine: "",
          workTime: "",
        };
        if (this.$refs.addForm) {
          this.$refs.addForm.resetFields();
        }
        this.edit = val;
        this.addDialog = true;
        if (val) {
          this.getInfo(val);
        }
      },
      // 查看详情
      getInfo(val) {
        this.$request(hotLineInfo, {
          id: val
        }).then((res) => {
          if (res.code === 0) {
            this.addForm = res.data;
            if (this.addForm.province && !this.addForm.city && !this.addForm.district) {
              this.addForm.arr = [this.addForm.province]
            } else if (this.addForm.province && this.addForm.city && !this.addForm.district) {
              this.addForm.arr = [
                this.addForm.province,
                this.addForm.city,
              ]
            } else if (this.addForm.province && this.addForm.city && this.addForm.district) {
              this.addForm.arr = [
                this.addForm.province,
                this.addForm.city,
                this.addForm.district,
              ]
            }
          }
        });
      },
      //   新增科普音频-上传接口
      addSubmit(val) {
        let url = this.edit ? hotLineUpdate : hotLineSave;
        this.$refs.addForm.validate((result) => {
          if (result) {
            this.$request(url, this.addForm).then((res) => {
              if (res.code === 0) {
                this.addDialog = false;
                this.$util.message("suc", "操作成功");
                this.getList();
              }
            });
          }
        });
      },
      showState(val) {
        switch (val) {
          case 0:
            return "上架";
          case 1:
            return "下架";
          case 2:
            return "待上架";
        }
      },
      stateClass(val) {
        switch (val) {
          case 0:
            return "green_dot";
          case 1:
            return "grey_dot";
          case 2:
            return "yellow_dot";
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .video {
    .add {
      padding-top: 3px;
      margin-right: 24px;
    }

    .right_btn {
      margin-left: 8px;
    }

    .img_240 {
      width: 50%;
      min-width: 150px;
      height: 180px;
      border: 1px solid #eeeeee;
    }

    /deep/.el-textarea__inner {
      padding: 5px 50px 5px 15px;
    }
  }
</style>